import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import IntroCards from 'components/720p_Series_CGI_List/IntroCGI';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='720p Serie CGI List' dateChanged='2019-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='720p Serie CGI List' image='/images/Search/AU_SearchThumb_CGICommands_720p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_720p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/720p_Series_CGI_List/' locationFR='/fr/720p_Series_CGI_List/' crumbLabel="720p CGIs" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <IntroCards mdxType="IntroCards" />
    {/* * <Link to="/720p_Series_CGI_List/Alarm_Menu/">Alarm Menu</Link>
     * <Link to="/720p_Series_CGI_List/Multimedia_Menu/">Multimedia Menu</Link>
     * <Link to="/720p_Series_CGI_List/Network_Menu/">Network Menu</Link>
     * <Link to="/720p_Series_CGI_List/Software_Menu/">Software Menu</Link>
     * <Link to="/720p_Series_CGI_List/System_Menu/">System Menu</Link>
     * <Link to="/720p_Series_CGI_List/Complete_CGI_List/">Complete CGI List</Link>
     * <Link to="/720p_Series_CGI_List/Recording_Menu/">Recording Menu</Link> */}
    <h2 {...{
      "id": "ip-camera-cgi-introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ip-camera-cgi-introduction",
        "aria-label": "ip camera cgi introduction permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IP Camera CGI Introduction`}</h2>
    <p>{`CGI listed in this file which is provided for your INSTAR IP camera, is an interface based on the HTTP protocol and the client application (e.g. a webpage in your web browsers or other applications). You can make various operations on the device by this set of CGI commands.`}</p>
    <p>{`CGI authentication: to access below CGI commands you need to authenticate your user level. Different CGI commands need different authority: visitor, operator or admin. The authentication is handled over the http protocol by attaching the user name and password directly to the CGI parameter. Example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`/cgi-bin/hi3510/cleanlog.cgi?usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <p>{`All the CGI commands support GET and POST operation - except upgrade.cgi and restore.cgi, which can only be used in a POST operation.`}</p>
    <p><strong parentName="p">{`GET Example:`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`form`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`form2`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`method`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`get`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`action`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`cgi-bin/hi3510/cleanlog.cgi`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`cururl`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`hidden`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`name`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`hidden`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`value`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`access`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`form`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p><strong parentName="p">{`POST Example:`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`form`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`form5`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`method`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`post`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`target`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`retframe`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`enctype`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`multipart/form-data`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token attr-name"
            }}>{`action`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`cgi-bin/hi3510/upgrade.cgi`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`onSubmit`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`return upgrade()`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`file`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`setting_file`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`submit`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`value`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{` submit`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`form`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p><strong parentName="p">{`CGI Return:`}</strong></p>
    <p>{`Depending on the returned info, the returns can be divided into two cases.`}</p>
    <p>{`GET CGI of device status and parameter, if successful，will return plain txt which includes the device status or parameter. The format is specified as javascript and every status or parameter as a variable:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` bps_1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2048"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` fps_1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"25"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` gop_1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"50"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` brmode_1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` imagegrade_1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` width_1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"704"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` height_1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"576"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`If the GET fails, it will return：`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`“[Error]GET video attribution error! errcode is 0xa10e6008”`}</code>{`.
Set device parameter will return plain txt including the result of the operation. If successful, the return will be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`HTTP/1.0 200 OK`}</code>{`. If the operation fails an error message will be returned, like: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`[Error][Data-Length:91] unrecognized`}</code>{` or incomplete command line..`}</p>
    <p>{`CGI commands can be typed into your web browsers address line directly for testing.`}</p>
    <h2 {...{
      "id": "list-of-the-most-common-cgi-commands",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#list-of-the-most-common-cgi-commands",
        "aria-label": "list of the most common cgi commands permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`List of the most common CGI commands`}</h2>
    <p>{`Both ways to authenticate are valid - e.g. for:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`user`}</code>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`admin`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`password`}</code>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`instar`}</code></li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://admin:instar@IP `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`. `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`usr`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <p>{`Both are used interchangeable in this documentation!`}</p>
    <h3 {...{
      "id": "video--snapshot",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#video--snapshot",
        "aria-label": "video  snapshot permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Video & Snapshot`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`VLC Stream (High Resolution)`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`rtsp://admin:instar@IP-Address:RTSP-Port/11`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`VLC Stream (Medium Resolution)`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`rtsp://admin:instar@IP-Address:RTSP-Port/12`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`VLC Stream (Low Resolution)`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`rtsp://admin:instar@IP-Address:RTSP-Port/13`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Snapshot (720p)`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/tmpfs/snap.jpg?usr=admin&pwd=instar`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Snapshot (352p)`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/tmpfs/auto.jpg?usr=admin&pwd=instar`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Snapshot (176p)`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/tmpfs/auto2.jpg?usr=admin&pwd=instar`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`MJPEG Stream 11`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/mjpegstream.cgi?-chn=11&-usr=admin&-pwd=instar`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`MJPEG Stream 12`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/mjpegstream.cgi?-chn=12&-usr=admin&-pwd=instar`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`MJPEG Stream 13`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/mjpegstream.cgi?-chn=13&-usr=admin&-pwd=instar`}</code></li>
        </ul>
      </li>
    </ul>
    <p><em parentName="p">{`Also note the additional SDK documentation for accessing video / audio`}</em></p>
    <h3 {...{
      "id": "pan--tilt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#pan--tilt",
        "aria-label": "pan  tilt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pan & Tilt`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Moves continuously right`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/ptzctrl.cgi?-step=0&-act=right`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Moves continuously left`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/ptzctrl.cgi?-step=0&-act=left`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Moves continuously up`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/ptzctrl.cgi?-step=0&-act=up`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Moves continuously down`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/ptzctrl.cgi?-step=0&-act=down`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Stops moving camera-head`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/ptzctrl.cgi?-step=0&-act=stop`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Moves one step right`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/ptzctrl.cgi?-step=1&-act=right`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Moves one step left`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/ptzctrl.cgi?-step=1&-act=left`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Moves one step up`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/ptzctrl.cgi?-step=1&-act=up`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Moves one step down`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/ptzctrl.cgi?-step=1&-act=down`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Go to Center Position`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/ptzctrl.cgi?-step=0&-act=home`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Scan horizontal`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/ptzctrl.cgi?-step=0&-act=hscan`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Scan vertical`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/ptzctrl.cgi?-step=0&-act=vscan`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Set a position`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/preset.cgi?-act=set&-status=1&-number=[0-7]`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Unset a position`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/preset.cgi?-act=set&-status=0&-number=[0-7]`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`goto to a set position`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/preset.cgi?-act=goto&-status=1&-number=[0-7]`}</code></li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "image",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#image",
        "aria-label": "image permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Image`}</h3>
    <ul>
      <li parentName="ul">{`Sets the Image Parameter`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/param.cgi?cmd=setimageattr`}</code></li>
        </ul>
      </li>
    </ul>
    <p>{`You can set the following parameters:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`brightness`}</strong>{` :: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`[0 - 255]`}</code>{` the bigger the value the brighter the image`}</li>
      <li parentName="ul"><strong parentName="li">{`saturation`}</strong>{` :: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`[0 - 255]`}</code>{` the bigger the value the more saturation the image has`}</li>
      <li parentName="ul"><strong parentName="li">{`contrast`}</strong>{` :: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`[0 - 255]`}</code>{` the bigger the value the more contrast the image has`}</li>
      <li parentName="ul"><strong parentName="li">{`hue`}</strong>{` :: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`[0 - 127]`}</code>{` the bigger the value the more hue the image has`}</li>
      <li parentName="ul"><strong parentName="li">{`flip`}</strong>{` :: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`[on , off]`}</code>{` flips the image`}</li>
      <li parentName="ul"><strong parentName="li">{`mirror`}</strong>{` :: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`[on , off]`}</code>{` flips the image`}</li>
      <li parentName="ul"><strong parentName="li">{`scene`}</strong>{` :: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`[auto , indoor , outdoor]`}</code>{` sets the white balance mode`}</li>
    </ul>
    <p><strong parentName="p">{`Example:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`WLAN`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/param.cgi?cmd=setimageattr&-brightness=0&-saturation=0&-contrast=0&-hue=0&-flip=off&-mirror=off&-scene=auto`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Reset the Parameter Values`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/param.cgi?cmd=setwirelessattr`}</code></li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`Example:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`IR-LED`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/cgi-bin/hi3510/param.cgi?cmd=setwirelessattr&-wf_ssid=SSID&-wf_enable=0&-wf_auth=2&`}</code>{`-wf_key=1234&-wf_enc=1&-wf_mode=0`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Sets IR LED's to auto mode`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://192.168.178.87/cgi-bin/hi3510/param.cgi?cmd=setinfrared&-infraredstat=auto`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Sets IR LED's always active :: only IN-6011`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://192.168.178.87/cgi-bin/hi3510/param.cgi?cmd=setinfrared&-infraredstat=open`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Sets IR LED's always inactive`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://192.168.178.87/cgi-bin/hi3510/param.cgi?cmd=setinfrared&-infraredstat=close`}</code></li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "alarm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm",
        "aria-label": "alarm permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`trigger an alarm on your camera`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.xxx.xxx/cgi-bin/hi3510/param.cgi?cmd=pushhostalarm`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`enables the md area 1`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.xxx.xxx/cgi-bin/hi3510/param.cgi?cmd=setmdattr&-enable=1&-name=1`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`disables the md area 1`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.xxx.xxx/cgi-bin/hi3510/param.cgi?cmd=setmdattr&-enable=0&-name=1`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`enable / disable external alarm in to trigger recording`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://192.168.x.x/cgi-bin/hi3510/param.cgi?cmd=setioattr&-io_enable=1&-io_flag=1`}</code></li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`Example:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Camera Model`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.xxx.xxx/cgi-bin/hi3510/param.cgi?cmd=setmdattr&-enable=0&-name=1&cmd=setmdattr&-enable=0&-name=2&cmd=setmdattr&-enable=0&-name=3&cmd=setmdattr&-enable=0&-name=4`}</code></li>
        </ul>
      </li>
    </ul>
    <p>{`Installing the wrong Firmware can overwrite your camera Identification. Use the following commands to reset your camera model:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Set your Camera Model`}</strong></p>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://192.168.178.133:83/param.cgi?cmd=get_instar_guest&-index=47`}</code></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Get set camera model`}</strong></p>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://192.168.178.133:83/param.cgi?cmd=set_instar_guest&-index=47&-value=6014`}</code></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Set PoE or WiFi Model:`}</strong></p>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://192.168.178.133:83/param.cgi?cmd=get_instar_guest&-index=48`}</code></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Get PoE variable :: PoE (true) or WLAN (false)`}</strong></p>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://192.168.178.133:83//param.cgi?cmd=set_instar_guest&-index=48&-value=false`}</code></li>
        </ul>
      </li>
    </ul>
    <p>{`If PoE is true, the WiFi menu will not be displayed!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      